<template>
  <CRow v-if="platformPermissionsLoaded && checkPermission('core.routes.overview')" class="routes">
    <CCol cols="12" lg="12" md="12">
      <CCard class="mb-0">
        <CCardHeader class="pb-0">
          <CRow>
            <CCol cols="6" xl="6" class="pt-0 text-left">
              {{$t('routes.Routes')}}
            </CCol>
            <CCol cols="6" xl="6" class="pt-0 pb-0 text-xl-right">
              <div class="mt-1 mt-xl-0 d-inline-block align-top">              
                <b-switch class="mt-0 mr-2" v-model="showInactive" size="is-small" @input="getRoutes()">{{$t('common.show_inactive')}}</b-switch>
                <CButton v-if="checkPermission('core.routes.add')" class="m-0" color="primary" @click="openSidebarRight('route_details', { route_id_external: null })">
                  <i class="fas fa-plus mr-1"/>
                  <span>{{$t('routes.Add_route')}}</span>
                </CButton>
              </div>
            </CCol>             
          </CRow>
        </CCardHeader>
        <CCardBody class="p-0">
          <CRow v-if="routesLoading === true">
            <CCol cols="12" lg="12">
              <loadingSpinner mode="auto" :content="$t('common.Loading')"/>
            </CCol>
          </CRow>
          <CRow v-else>
            <CCol cols="12" lg="12" v-bind:class="{'pt-0 pb-0': routes.length > 0}">
              <div v-if="routes.length > 0">
                <b-table ref="routesTable"
                         class="data_table"
                         :data="routes"
                         :striped="true"
                         :hoverable="true"
                         :mobile-cards="true"
                         :current-page.sync="currentPage"
                         :paginated="isPaginated"
                         :per-page="perPage"
                         :pagination-simple="isPaginationSimple"
                         :pagination-position="paginationPosition"
                         :default-sort="[sortField, sortOrder]"
                         :default-sort-direction="defaultSortDirection"
                         :sort-icon="sortIcon"
                         :sort-icon-size="sortIconSize">
                  
                  <template slot-scope="props">
                    <b-table-column field="route_name" :label="$t('routes.Route_name')" :searchable="searchEnabled" :sortable="true" width="30%">
                      <span>{{props.row.route_name}}</span>
                      <div class="item_tags d-flex flex-row">
                        <b-tag v-if="!props.row.is_active" class="mt-1 inactive">{{ $t('common.Inactive') }}</b-tag>
                      </div>                      
                    </b-table-column>
                    <b-table-column field="route_description" :label="$t('common.Description')" :searchable="searchEnabled">
                      <span>{{props.row.route_description}}</span>
                    </b-table-column>
                    <b-table-column field="action" width="10%" :visible="checkPermission('core.routes.edit')">
                      <div class="d-flex justify-content-lg-end">
                        <CButton color="primary" class="m-0" @click="openSidebarRight('route_details', { route_id_external: props.row.route_id_external })">
                          <i class="fas fa-pen"/>
                        </CButton>
                      </div>
                    </b-table-column>                                        
                  </template>              
                </b-table>
              </div>
              <div v-else class="text-center">                
                <span>{{$t('service.No_folders_available')}}</span>
              </div>                
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
  <noPermission v-else-if="platformPermissionsLoaded" trigger="permission"/>
</template>

<script>
import axios from 'axios'
import QuickEdit from 'vue-quick-edit';
import Multiselect from 'vue-multiselect';

import loadingSpinner from '@/components/common/loadingSpinner.vue';
import noPermission from '@/components/common/noPermission.vue';

export default {
  name: 'Folders',
  components: {
    QuickEdit,
    Multiselect,
    loadingSpinner,
    noPermission
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null,      
      platformPermissions: [],
      platformPermissionsLoaded: false,      
      routes: [],
      routesLoading: true,
      routesLoaded: false,
      showInactive: false,
      sortField: 'route_name',
      sortOrder: 'asc',
      defaultSortDirection: 'asc',
      isPaginated: false,
      isPaginationSimple: false,
      paginationPosition: 'bottom',
      currentPage: 1,
      perPage: 10,
      sortIcon: 'arrow-up',
      sortIconSize: 'is-small',
      searchEnabled: true
    }
  },
  methods: {
    getRoutes() {
      // Start the loader
      if(this.routesLoaded === false) this.routesLoading = true;
      // Get the routes
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/routes/overview')
      .then(res => {
        this.routes = res.data.data;
        // Filter on active status if necessary
        if(!this.showInactive) this.routes = this.routes.filter( i => ['Y'].includes( i.active ) );
        // Enable the pagination if necessary
        (this.routes.length > this.perPage) ? this.isPaginated = true : this.isPaginated = false;        
        // Stop the loader
        this.routesLoading = false;
        // Update the routesLoaded value
        this.routesLoaded = true; 
      })
      .catch(err => {
        console.error(err); 
      });
    },       
    getPlatformPermissions() {
      axios.get(process.env.VUE_APP_API_URL + '/v1/core/platform/permissions')
      .then(res => {      
        this.platformPermissions = res.data.data;
        // Update the platformPermissionsLoaded value
        this.platformPermissionsLoaded = true;
      })
      .catch(err => {
        console.error(err); 
      });
    },
    checkPermission(permissionTag) {
      if(this.platformPermissions.includes(permissionTag)) {
        return true;
      } else{
        return false;
      }
    }          
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');
        
    this.getPlatformPermissions();
    this.getRoutes();

    this.$bus.$on('update_routes', () => {
      this.getRoutes();
    });  
  },
  beforeDestroy() {
    this.$bus.$off('update_routes');
  }
}
</script>